<template>
    <v-container class="footer bg-teal-darken-4">
        <v-row>
            <v-col cols="12" sm="6">
                <v-sheet class="" color="transparent">
                    <v-row class="" no-gutters>
                        <v-col>
                            <v-sheet class="" color="transparent" style="font-size: 1.5em;">
                                <!-- <b style="color: #ffffff;">Follow Us</b> -->
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-row v-for="item in social_media_items" :key="item" no-gutters>
                        <v-col>
                            <v-sheet class="" color="transparent">
                                <v-row style="align-items: center;" no-gutters>
                                    <div style="display: flex;color: #ffffff;">
                                        <v-icon style="align-items: center;">{{ item.icon }}</v-icon>
                                        <p class="ml-1" style="font-size: 1em; padding: 2px">{{ item.name }}</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-row class="" no-gutters>
                        <v-col>
                            <v-sheet class="" color="transparent" style="font-size: 1em;">
                                <router-link to="/product/fancy_light" style="text-decoration:none;">
                                    <b style="color: #ffffff; ">Fancy Light<br></b>
                                </router-link>
                                <router-link to="/product/tech_tick" style="text-decoration:none;">
                                    <b style="color: #ffffff; ">Tech Tick<br></b>
                                </router-link>
                                <router-link to="/about" style="text-decoration:none;">
                                    <b style="color: #ffffff; ">關於我們<br></b>
                                </router-link>
                                <router-link to="/privacy-policy" style="text-decoration:none;">
                                    <b style="color: #ffffff;">
                                        私隱政策</b>
                                </router-link>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-col>
            <v-col cols=" 12" sm="6">
                <v-sheet class="" color="transparent">
                    <v-row class="" no-gutters>
                        <v-col>
                            <v-sheet class="" color="transparent" style="font-size: 1.5em; ">
                                <b style="color: #ffffff;">聯絡我們</b>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <!-- <v-row v-for=" item in contact_item" :key="item" no-gutters>
                        <v-col>
                            <v-sheet class="" color="transparent">
                                <v-row style="align-items: center; color: #ffffff;" no-gutters>
                                    <div style="display: flex;">
                                        <v-icon style="align-items: center;">{{ item.icon
                                        }}</v-icon>
                                        <p class="ml-1" style="font-size: 1em; padding: 2px">{{
                                            item.name }}</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-col>
                    </v-row> -->

                    <v-row no-gutters>
                        <v-col>
                            <v-sheet class="" color="transparent">
                                <v-row style="align-items: center; color: #ffffff;" no-gutters>
                                    <div style="display: flex;">
                                        <v-icon style="align-items: center;">mdi-email</v-icon>
                                        <p class="ml-1" style="font-size: 1em; padding: 2px">
                                            <!-- <a style="text-decoration:none; color: #FFFFFF;"
                                                href="mailto:info@dreamiversehk.com">
                                                info@dreamiversehk.com
                                            </a> -->
                                            <a style="text-decoration:none; color: #FFFFFF;"
                                                href="mailto:info@dreamiversehk.com">
                                                info@dreamiversehk.com
                                            </a>
                                        </p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-sheet class="" color="transparent">
                                <v-row style="align-items: center; color: #ffffff;" no-gutters>
                                    <div style="display: flex;">
                                        <v-icon style="align-items: center;">mdi-map-marker</v-icon>
                                        <p class="ml-1" style="font-size: 1em; padding: 2px">新界火炭坳背灣街33-35號世紀工業中心5樓K室</p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row>
            <v-col col="12">
                <v-sheet class="" color="transparent" style="text-align: center;">
                    <p style="color: #ffffff;">Copyright 2024 © Dreamiverse Technology</p>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.footer {
    min-width: 100%;
}

@media (min-width: 600px) {
    .footer {
        padding: 1% 10% 0% 10%;
    }
}
</style>

<script>

export default {

    data: () => ({
        // social_media_items: [
        //     {
        //         icon: 'mdi-facebook',
        //         name: 'DreamiverseHK',
        //     },
        //     {
        //         icon: 'mdi-instagram',
        //         name: 'Dreamiverse_HK',
        //     },
        // ],
        contact_item: [
            {
                icon: 'mdi-email',
                name: 'info@dreamiversehk.com',
            },
            // {
            //     icon: 'mdi-whatsapp',
            //     name: '+852 9266 4758',
            // },
            {
                icon: 'mdi-map-marker',
                name: '新界火炭坳背灣街33-35號世紀工業中心5樓K室',
            },
        ],
    }),
}
</script>