<template>
  <div class="home">
    <v-carousel cycle height="400" hide-delimiter-background show-arrows="hover" class="pb-3">
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" cover></v-carousel-item>
    </v-carousel>

    <v-container class="pa-6">
      <v-row>
        <v-col>
          <v-card class="" variant="flat">
            <v-card-title class="text-h6 text-md-h5 text-lg-h4 text-center font-weight-bold pa-6">
              <!-- 致力於提供具有吸引力和有效性的STEM教育方案和配件 -->
              <!-- 致力於設計和開發與學生需求和興趣相吻合的教育方案 -->
              致力開發有趣的STEM教育方案
            </v-card-title>
            <v-card-content>
              <v-sheet>
                <v-row>
                  <v-col cols="12" sm="5">
                    <!-- <v-img class="align-end text-white" height="300" src="../assets/Home/IMG_20231209_175031.jpg" cover> -->
                    <v-img class="align-end text-white" height="350" src="../assets/Home/IMG_20231209_175031.jpg" cover>
                    </v-img>
                  </v-col>
                  <v-col cols="12" sm="7">
                    Dreamiverse是為教育工作者提供全面STEAM教育支援的平台。<br><br>
                    從硬體和軟體資源到課程計畫和活動，我們提供一切中小學課堂實施STEAM所需的支援。<br><br>
                    透過實踐和專案，引導學生學習科學、技術、工程和數學的概念和原理。同時，我們提供多樣的配件和工具，包括硬體設備、程式設計工具和實驗器材，支援學生進行實驗、建模和創作。我們的目標是提供最新、最具創新性的資源，確保教育工作者能為學生提供令人興奮且有意義的STEM學習體驗。<br><br>
                    Dreamiverse助力教育工作者在課堂中實施難忘的STEM教育，激發學生的好奇心和創造力，培養他們在科學、技術、工程和數學領域的技能和能力。
                  </v-col>
                </v-row>
              </v-sheet>
            </v-card-content>
          </v-card>
        </v-col>
      </v-row>
    </v-container>





    <div class="bg-teal-lighten-5 pa-6">
      <v-card class="bg-teal-lighten-5" variant="flat">
        <v-card-title class="text-h6 text-md-h5 text-lg-h4 text-center font-weight-bold">
          <!-- 致力於提供具有吸引力和有效性的STEM教育方案和配件 -->
          <!-- 致力於設計和開發與學生需求和興趣相吻合的教育方案 -->
          我們的教育套件
        </v-card-title>
      </v-card>
      <v-container>
        <v-row align="center" justify="center">
          <!-- <v-col cols="12" sm="6">
          <v-card class="mx-auto">
            <v-img class="align-end text-white" height="300" src="../assets/Home/1.jpeg" cover>
              <v-card-title>FancyLight</v-card-title>
            </v-img>

            <v-card-text>
              <div>結合創意和學習的教學套件</div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="orange" :to="'/product/fancy-light'">
                了解更多
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col> -->
          <v-col cols="12" sm="6">
            <v-card class="mx-auto" style="" :to="'/product/fancy_light'" cover>
              <v-img class="align-end text-white" src="../assets/Product/FancyLight/ProductImage/1.jpeg">
                <v-card-title>Fancy Light</v-card-title>
              </v-img>

              <!-- <v-card-subtitle class="pt-4">
                探索無限之光
              </v-card-subtitle> -->

              <v-card-text>
                <div>利用光的反射原理所創造出的空間視覺，配合編程達到不同的視覺效果 。</div>
                <!-- <div>Whitsunday Island, Whitsunday Islands</div> -->
              </v-card-text>
              <v-card-actions>
                <v-btn :to="'/product/fancy_light'">
                  了解更多
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6">
            <v-card class="mx-auto" :to="'/product/tech_tick'">
              <v-img class="align-end text-white" src="../assets/Product/TechTick/ProductImage/1.jpeg" cover>
                <v-card-title>Tech Tick</v-card-title>
              </v-img>
              <!-- 
              <v-card-subtitle class="pt-4">
                Number 10
              </v-card-subtitle> -->

              <v-card-text>
                <div>探索時間與科技的交匯點。<br></div>
                <!-- <div>Whitsunday Island, Whitsunday Islands</div> -->
              </v-card-text>
              <v-card-actions>
                <v-btn :to="'/product/tech_tick'">
                  了解更多
                </v-btn>
              </v-card-actions>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- <v-container>
      <v-card title="我們的教育套件" text="..." variant=""></v-card>
      <h1 style="text-align: center;">我們的教育套件</h1>
      <v-card class="mx-auto" max-width="75%" style="margin: 3%;">
        <v-container fluid>
          <v-row>
            <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
              <v-card>
                <v-img :src="card.src" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px" cover>
                  <v-card-title class="text-white" v-text="card.title"></v-card-title>
                </v-img>

                <v-card-text>
                  <div>Whitehaven Beach</div>

                  <div>Whitsunday Island, Whitsunday Islands</div>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn size="small" color="surface-variant" variant="text" icon="mdi-heart"></v-btn>

                  <v-btn size="small" color="surface-variant" variant="text" icon="mdi-bookmark"></v-btn>

                  <v-btn size="small" color="surface-variant" variant="text" icon="mdi-share-variant"></v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container> -->


    <v-container class="pa-6">
      <v-row>
        <v-col>
          <v-card class="" variant="flat">
            <v-card-title class="text-h6 text-md-h5 text-lg-h4 text-center font-weight-bold pa-6">
              <!-- 致力於提供具有吸引力和有效性的STEM教育方案和配件 -->
              <!-- 致力於設計和開發與學生需求和興趣相吻合的教育方案 -->
              為何選擇我們
            </v-card-title>
            <v-card-content>
              <v-sheet>
                <v-row>

                  <v-col v-for="item in why_choose_us" :key="item.icon" class="" cols="12" sm="4">
                    <div style="display: flex; height: 150px;">
                      <v-img :src="`${item.icon}`"></v-img>
                    </div>
                    <p class="text-center text-h5 mt-3 mb-3">
                      {{ item.name }}
                    </p>
                    <p class="text-justify text-body-1">
                      {{ item.content }}
                    </p>
                  </v-col>

                </v-row>
              </v-sheet>
            </v-card-content>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
        },
        {
          src: 'https://gd2.alicdn.com/imgextra/i2/2206845172384/O1CN01tFXuh91TTv042FWy0_!!2206845172384.jpg',
        },
      ],
      cards: [
        { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 6 },
        { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },

      ],
      why_choose_us: [
        {
          icon: require('@/assets/AboutUs/explore.svg'),
          name: '創新的STEM教育',
          content: '我們提供針對初中學生的創新STEM教育解決方案。我們的課程引人入勝、具有挑戰性，符合最新的教育標準，為老師提供了一個全面的資源，以增強他們的課堂教學。',
        },
        {
          icon: require('@/assets/Home/全面的課程內容.svg'),
          name: '全面的課程內容',
          content: '我們的課程涵蓋廣泛的STEM主題，包括電子學、機械工程、程式設計和建模等。課程精心設計，旨在培養學生的批判思維、解決問題和創造力，培養他們對STEM科目的興趣和能力。',
        },
        {
          icon: require('@/assets/Home/實踐應用.svg'),
          name: '實踐應用',
          content: '我們的課程強調實踐學習和知識應用。學生參與實驗、項目和真實情境模擬，使他們能夠將所學知識應用於真實生活中。這種教學方法增強了概念的理解和記憶。',
        },
        {
          icon: require('@/assets/Home/經驗豐富的教育專業人員.svg'),
          name: '經驗豐富的教育專業人員',
          content: '我們的教學團隊由經驗豐富的教育專業人員和STEM專家組成，他們熱衷於啟發和指導學生的STEM之旅。他們具備提供高質量教學、創建互動學習環境和為學生提供個別支援的專業知識。',
        },
        {
          icon: require('@/assets/Home/廣泛的學習資源.svg'),
          name: '廣泛的學習資源',
          content: '我們提供豐富的學習資源，包括教案、活動工作表、評估工具和補充資料。這些資源節省了老師準備課程的時間，並為他們提供了一個全面的工具箱，用於提供引人入勝和有效的STEM教育。',
        },
        {
          icon: require('@/assets/AboutUs/合作夥伴關係.svg'),
          name: '合作夥伴關係',
          content: '我們重視與老師的緊密合作夥伴關係。我們重視他們的意見，納入他們的反饋，並持續合作改進我們的課程。我們提供持續的支援、專業發展機會，以及一個讓老師與同行交流想法和最佳實踐的平台。',
        },
      ],
    }
  },
}
</script>
