import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Dreamiverse' }
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  // {
  //   path: '/product',
  //   name: 'product',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ProductView.vue')
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { title: 'About Us' }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
    meta: { title: 'Contact Us' }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyView.vue'),
    meta: { title: 'Privacy Policy' }
  },
  {
    path: '/product/fancy_light',
    name: 'fancy_light',
    component: () => import(/* webpackChunkName: "about" */ '../views/FancyLightView.vue'),
    meta: { title: 'Fancy Light' }
  },
  {
    path: '/product/tech_tick',
    name: 'tech_tick',
    component: () => import(/* webpackChunkName: "about" */ '../views/TechTickView.vue'),
    meta: { title: 'Tech Tick' }
  },
  {
    path: '/:Match(.*)',
    name: 'not_found',

    component: () => import(/* webpackChunkName: "about" */ '../views/404View.vue'),
    meta: { title: '404' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    }
    return { left: 0, top: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
})

export default router
