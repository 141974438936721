<template>
    <div>
    <v-app-bar app scroll-behavior="hide" density="prominent" flat style="background-color:rgba(255, 255, 255, 0.8);">
        <template v-slot:prepend>
            <router-link to="/">
                <v-app-bar-side-icon>
                    <v-img src="@/assets/logo.png" height="80px" width="150px">
                    </v-img>
                </v-app-bar-side-icon>
            </router-link>

            <div class="hidden-sm-and-down">
                <p style="font-size: 1.9em;">Dreamiverse Technology</p>
                <span style="font-size:1em">Empowering, Exploring, Embracing with Dreamiverse</span>
            </div>
            <v-spacer></v-spacer>
        </template>

        <template v-slot:append>
            <div class="hidden-md-and-up mr-6">
                <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </div>

            <v-app-bar-items class="d-none d-md-flex d-xs-none hidden-xs-only">
                <router-link to="/product/fancy_light" style="text-decoration: none;">
                    <v-btn class="mx-2" style="text-decoration:none;" color="black darken-1" size="x-large">
                        Facny Light
                    </v-btn>
                </router-link>
                <router-link to="/product/tech_tick" style="text-decoration: none;">
                    <v-btn class="mx-2" style="text-decoration:none;" color="black darken-1" size="x-large">
                        Tech Tick
                    </v-btn>
                </router-link>

                <router-link to="/about" style="text-decoration: none;">
                    <v-btn class="mx-2" style="text-decoration:none;" color="black darken-1" size="x-large">
                        關於我們
                    </v-btn>
                </router-link>

                <!-- <router-link to="/contact" style="text-decoration: none;">
                    <v-btn class="mx-2" style="text-decoration:none;" color="black darken-1" size="x-large">
                        聯絡我們
                    </v-btn>
                </router-link> -->
            </v-app-bar-items>
        </template>
    </v-app-bar>

    <!-- <v-navigation-drawer v-model="drawer"  :width="150" location="right" style="background-color:rgba(224, 242, 234, 0.8);"  >
            <v-list>
                <v-list-item v-for="(item, index) in items " :key="index" :value="index">
                    <router-link :to="item.path" style="text-decoration:none; color: #000000;">
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                    </router-link>
                </v-list-item>
            </v-list>
        </v-navigation-drawer> -->

    <v-navigation-drawer width="150" class="wh-100 h-screen" v-model="drawer" location="right" temporary
        style="background-color:rgba(224, 242, 234, 0.9);">
        <v-list>
            <router-link v-for="(item, index) in items " :key="index" :to="item.path"
                style="text-decoration:none; color: #000000;">
                <v-list-item :value="index">
                    <v-list-item-title>
                        {{ item.title }}
                    </v-list-item-title>
                </v-list-item>
            </router-link>
        </v-list>
    </v-navigation-drawer>
</div>
</template>



<style>
.v-navigation-drawer__scrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 999;
    /* pointer-events: none; */
    transition: background-color 0.3s;
}
</style>



<script>
export default {
    name: 'AppBar',

    data: () => ({
        drawer: false,
        items: [
            { title: 'Facny Light', path: '/product/fancy_light', },
            { title: 'Tech Tick', path: '/product/tech_tick', },
            { title: '關於我們', path: '/about', },
            // { title: '聯絡我們', path: '/contact', },

        ],
    }),
};



</script>

<!-- <style scoped>
.custom-link {
    text-decoration: none;
    color: inherit;

}
</style> -->